<template>

  <v-main
      class="set-bg"
  >
    <v-container style="width: 100%;height: 100%;"
                 class="d-flex align-center justify-center">
      <v-card v-if="dataTracking.order_transportation_status ==0"
              elevation="5"
              color="#ffffff8a"
              style="backdrop-filter: blur(5px);"
      >
        <v-card-title>
          <v-spacer></v-spacer>
          {{ dataTracking.order_transportation_text }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-img src="@/assets/images/wait.gif" width="500" class="rounded-lg"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <strong>กรุณาตรวจสอบอีกครั้ง ในภายหลัง ...</strong>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card v-else width="700"
              elevation="5"
              color="#ffffff8a"
              style="backdrop-filter: blur(5px);">
        <v-card-title class="text-h3">
          รายการ Tracking
        </v-card-title>
        <v-divider></v-divider>
        <v-list two-line color="transparent">
          <v-list-item
              v-for="(tracking,index) in dataTracking.tracking"
              :key="index"

          >
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center  text-h4">
                <v-icon
                    class="me-2"
                    large
                    :color="tracking.tracking_status ===0?'warning':tracking.tracking_status ===1?'success':'error'">
                  {{
                    tracking.tracking_status === 0 ? mdiTruckDeliveryOutline : tracking.tracking_status === 1 ? mdiTruckCheckOutline : mdiAlertRemoveOutline
                  }}
                </v-icon>
                {{ tracking.tracking_number }}
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                        large
                        color='primary'
                        icon
                        :href="`https://www-lotteglogis-com.translate.goog/home/reservation/tracking/linkView?InvNo=${tracking.tracking_number}&_x_tr_sl=auto&_x_tr_tl=th&_x_tr_hl=en-US`"
                        v-bind='attrs'
                        target="_blank"
                        v-on='on'
                    >
                      <v-icon large>{{ mdiTextBoxCheckOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>ตรวจสอบสถานะ</span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { onMounted, ref } from "@vue/composition-api";
import { api } from "@/services/api"
import CurrentCurrency from "@/components/CurrentCurrency";
import StatusOrderTracking from "@/components/StatusOrderTracking";
import {
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiCheck,
  mdiTextBoxCheck,
  mdiTruckCheckOutline,
  mdiTruckDeliveryOutline,
  mdiTextBoxCheckOutline,
  mdiAlertRemoveOutline,

} from '@mdi/js'

export default {
  props: {},
  components: { StatusOrderTracking },
  setup(_, { root, emit }) {
    const dataTracking = ref({})
    onMounted(() => {
      getData()
    });
    const getData = async () => {
      const { data } = await api.get({
        path: 'orderTransportation',
        param: root.$route.params.id,
      })
      dataTracking.value = data
      console.log('data : ', data)
    }
    return {
      dataTracking,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiCheck,
      mdiTextBoxCheck,
      mdiTruckCheckOutline,
      mdiTruckDeliveryOutline,
      mdiTextBoxCheckOutline,
      mdiAlertRemoveOutline,
    }
  },

}
</script>

<style scoped>
.set-bg {
  background: url('https://ariscargo.bg/en/pictures/pic_big/homepics/home(1).jpg');
  width: 100%;
  height: 100%;
  content: '';
  inset: 0;
  background-position: right, right;
  background-size: cover;
}

</style>
